import { createApp } from "vue";
import App from "./app/App.vue";
import { router } from "./_helpers";
import { store } from "./_store";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import pusherPlugin from "./_helpers/pusher-plugin";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_API_KEY,
		libraries: ["places", "geometry"],
		language: "id",
	},
});
app.use(pusherPlugin, {
	key: process.env.VUE_APP_PUSHER_KEY,
	cluster: process.env.VUE_APP_PUSHER_CLUSTER,
	host: process.env.VUE_APP_PUSHER_HOST,
	port: process.env.VUE_APP_PUSHER_PORT,
	authEndpoint: process.env.VUE_APP_PUSHER_ENDPOINT,
});
app.mount("#app");
